import React, { useEffect } from "react";

import Hero from "@components/shared/hero";
import SanityCareerBlock from "@components/pages-components/careers/careerBlock";
import SEO from "@components/shared/seo";
import LayOut from "@components/layout";
import { graphql, PageProps } from "gatsby";
import { Query, SanityCareersPageConnection } from "@graphql-types";

interface Data extends Query {
  altRegions: SanityCareersPageConnection;
}
import { useRegionChanged } from "@util/hooks";

interface Props extends PageProps {
  data: Data;
  pageContext: any;
}

const Careers = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useRegionChanged("careers");

  const sanityCareersPage = props.data.allSanityCareersPage.nodes[0];
  const alternateRegions = props.data.altRegions.nodes.map(alt => alt._rawRegion);

  if (!sanityCareersPage) {
    return null;
  }

  return (
    <>
      <SEO
        seoData={sanityCareersPage.seo}
        alternateRegions={alternateRegions}
        slug={props.pageContext.slug}
      />

      <LayOut>
        <Hero singleHeroContent={sanityCareersPage.hero} featuredHero={true} />

        {sanityCareersPage?._rawCareers &&
          !sanityCareersPage?._rawCareers?.careers && (
            <SanityCareerBlock
              noCareers
              noCareerContent={sanityCareersPage._rawCareers}
              defaultImage={sanityCareersPage.careerImage}
            />
          )}
        {sanityCareersPage?._rawCareers?.careers &&
          sanityCareersPage?._rawCareers.careers.map(career => {
            return (
              <SanityCareerBlock
                careerBlock={career}
                defaultImage={sanityCareersPage.careerImage}
                key={career?._key}
              />
            );
          })}
      </LayOut>
    </>
  );
};

export default Careers;

export const query = graphql`
  query careerQuery($iban: String) {
    allSanityCareersPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        careerImage {
          ...sanityImageWithMeta
        }
        _rawCareers(resolveReferences: {maxDepth: 6})
        careers {
          careerMessage {
            title
            description {
              _rawColumnContent(resolveReferences: { maxDepth: 5 })
            }
          }
          careers {
            _key
            jobTitle
            description
            link {
              ...sanityLink
            }
          }
        }
      }
    }
    altRegions: allSanityCareersPage {
      nodes {
        _rawRegion(resolveReferences:{maxDepth:10})
      }
    }
  }
`;